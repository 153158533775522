import logo from './logo.svg';
import './App.css';
import Home from './thinktrek/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
    
     <Routes>
     
     <Route path="/" element={<Home/>} />
     {/* <Route path="privacy" element={<Privacy/>} />
     <Route path="support" element={<Support/>} />
     <Route path="terms" element={<Terms/>} /> */}
     </Routes>
   
   </BrowserRouter>
    </div>
  );
}

export default App;
